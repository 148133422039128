<script>
  import { dateToString } from '../lib/tools';

  export let value = null;
  export let seconds = false;
  export let fallback = '<span />';

  let isoString;
  let dateString;
  let tooltip;
  $: {
    if (value) {
      let date = value;
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      isoString = date.toISOString();
      dateString = dateToString(date, seconds);
      tooltip = seconds ? '' : `uk-tooltip="title: ${isoString}"`;
    }
  }
</script>

{#if value}
  <time class="uk-text-nowrap" {tooltip} datetime={isoString}>{dateString}</time>
{:else}
  {@html fallback}
{/if}
