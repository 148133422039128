<script>
  export let href;
  /** @type {UIIcon} */
  export let icon;
  export let ratio = 1;
  export let tooltip = null;
</script>

<style>
  a {
    display: inline-block;
  }
</style>

{#if href && icon}
  <a {href} target="_blank">
    <span
      uk-icon="icon: {icon}; ratio: {ratio}"
      uk-tooltip={tooltip ? `title: ${tooltip}` : undefined} />
    <span>
      <slot />
    </span>
  </a>
{/if}
