import { writable } from 'svelte/store';
import { navigateTo } from 'svelte-router-spa';

import { errorToast } from './errors';
import { getPrincipal, requestLogout } from './api';
import { DEFAULT_LOGGED_OUT_ROUTE } from './routing';

export const principalStore = writable(undefined);

export async function loadPrincipal() {
  let principal;
  try {
    principal = await getPrincipal();
  } catch (err) {
    principal = null;
    if (err.statusCode !== 401) {
      // Some other error
      errorToast(err);
    }
  }
  principalStore.update(() => principal);
  return principal;
}

export async function logout() {
  principalStore.update(() => null);
  navigateTo(DEFAULT_LOGGED_OUT_ROUTE.url);
  try {
    await requestLogout();
    return true;
  } catch (_) {
    // We don't care
    return false;
  }
}
