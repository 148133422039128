<script>
  import { Navigate, navigateTo } from 'svelte-router-spa';

  import DateTime from './DateTime.svelte';

  /** @type {DataTableColumn} */
  export let column;
  export let item;

  let value;
  let component;
  let slot;
  $: {
    if (column && item) {
      if (typeof column.render === 'function') {
        value = column.render(item[column.field], item, column);
      } else if (typeof column.renderItem === 'function') {
        value = column.renderItem(item, column);
      } else {
        value = item[column.field];
      }
      if (value === null || value === undefined) {
        value = '';
      }
      if (value instanceof Date) {
        // Convert render to date
        value = { component: DateTime, value: value };
      }
      if (typeof value === 'object') {
        // Render as component. We will split out component and props, because... svelte.
        // NOTE: potential mutation, but probably OK, since ad-hoc object is generated by "render"
        component = value.component;
        delete value.component;
        slot = value.slot;
        delete value.slot;
      }
    }
  }
</script>

<style>
  :global(.DataTableCell.uk-table-link a) {
    font-weight: bold;
  }
</style>

{#if column.urlTo}
  <td class="DataTableCell uk-table-link">
    <Navigate to={column.urlTo(item)}>
      {#if component}
        {#if slot !== undefined}
          <svelte:component this={component} {...value}>{slot}</svelte:component>
        {:else}
          <svelte:component this={component} {...value} />
        {/if}
      {:else}
        {@html value}
      {/if}
    </Navigate>
  </td>
{:else}
  <td class="DataTableCell">
    {#if component}
      {#if slot !== undefined}
        <svelte:component this={component} {...value}>{slot}</svelte:component>
      {:else}
        <svelte:component this={component} {...value} />
      {/if}
    {:else}
      {@html value}
    {/if}
  </td>
{/if}
