<script>
  export let rating;

  let stars;
  $: {
    stars = [];
    for (let i = 1; i <= rating; i++) {
      stars.push(true);
    }
  }
</script>

<style>
  .StarRating {
    white-space: nowrap;
  }
  .StarRating svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }
  .StarRating-1 svg path {
    fill: hsla(30, 100%, 56%, 1);
  }
  .StarRating-2 svg path {
    fill: hsla(35, 100%, 56%, 1);
  }
  .StarRating-3 svg path {
    fill: hsla(40, 100%, 56%, 1);
    animation: color_change_3 1s infinite alternate;
  }
  .StarRating-4 svg path {
    fill: hsla(45, 100%, 56%, 1);
    animation: color_change_4 1s infinite alternate;
  }
  .StarRating-5 svg path {
    fill: hsla(50, 100%, 56%, 1);
    animation: color_change_5 1s infinite alternate;
  }

  @keyframes color_change_3 {
    from {
      fill: hsla(40, 100%, 56%, 1);
    }
    to {
      fill: hsla(40, 100%, 59%, 0.9);
    }
  }
  @keyframes color_change_4 {
    from {
      fill: hsla(45, 100%, 56%, 1);
    }
    to {
      fill: hsla(45, 100%, 62%, 0.8);
    }
  }
  @keyframes color_change_5 {
    from {
      fill: hsla(50, 100%, 56%, 1);
    }
    to {
      fill: hsla(50, 100%, 66%, 0.7);
    }
  }

  .StarRating svg:nth-child(1) path {
    animation-delay: 0s;
  }
  .StarRating svg:nth-child(2) path {
    animation-delay: 0.15s;
  }
  .StarRating svg:nth-child(3) path {
    animation-delay: 0.3s;
  }
  .StarRating svg:nth-child(4) path {
    animation-delay: 0.45s;
  }
  .StarRating svg:nth-child(5) path {
    animation-delay: 0.6s;
  }
</style>

{#if rating >= 0 && rating <= 5}
  <span class="StarRating StarRating-{rating}">
    {#each stars as star}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <path fill="" d="m48,234 73-226 73,226-192-140h238z" />
      </svg>
    {/each}
  </span>
{/if}
