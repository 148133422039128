<script>
  import { onMount } from 'svelte';
  import { Navigate } from 'svelte-router-spa';

  import { ROUTES, breadcrumbsStore } from '../../lib/routing';
  import { fetchCandidate, makeShowFileUrl } from '../../lib/api';
  import { handleError } from '../../lib/errors';
  import { cancellator, loader } from '../../lib/tools';
  import Layout from '../../layout/Layout.svelte';
  import PageTitle from '../../layout/PageTitle.svelte';
  import BooleanBadge from '../../widgets/BooleanBadge.svelte';
  import DateTime from '../../widgets/DateTime.svelte';
  import IndicatorIcon from '../../widgets/IndicatorIcon.svelte';
  import StarRating from '../../widgets/StarRating.svelte';
  import CandidateLinks from './CandidateLinks.svelte';
  import CandidateFiles from './CandidateFiles.svelte';
  import Comment from './Comment.svelte';

  export let currentRoute;
  export let params;

  const fallback = '<span class="uk-text-muted uk-text-italic">None</span>';

  onMount(() =>
    breadcrumbsStore.update(() => [
      ROUTES.candidates(),
      ROUTES.candidateDetails(currentRoute.namedParams.id),
    ])
  );

  /** @type {CandidateInfo} */
  let data = null;
  let loading = loader.initial;

  async function loadData(id) {
    loading = loader.start();
    data = await fetchCandidate(id)
      .catch(handleError)
      .then(cancellator(loading, () => loading));
    loading = loader.done;
  }

  onMount(() => loadData(currentRoute.namedParams.id));
</script>

<style>
  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }

  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .comment + .comment {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
  }
</style>

<Layout {loading}>
  <div class="uk-container">
    {#if !data}
      <IndicatorIcon {loading} error ratio={5} className="uk-position-center" />
    {:else}
      <PageTitle>{data.first_name} {data.last_name}</PageTitle>

      <div class="uk-grid-divider uk-child-width-expand@m" uk-grid>
        <dl class="uk-description-list uk-description-list-divider">
          <dt>Email</dt>
          <dd>
            <a href="mailto: {data.email}" target="_blank">{data.email}</a>
          </dd>
          <dt>Rating</dt>
          <dd>
            <StarRating rating={data.rating} />
          </dd>
          <dt>Status</dt>
          <dd>{data.status}</dd>
          <dt>Position</dt>
          <dd>
            <Navigate to={ROUTES.positionDetails(data.position_id).url}>
              {data.position_title}
            </Navigate>
          </dd>
          <dt>Links</dt>
          <dd>
            <CandidateLinks candidate={data} {fallback} />
          </dd>
          <dt>Files</dt>
          <dd>
            <CandidateFiles candidate={data} {fallback} />
          </dd>
          <dt>Phone</dt>
          <dd>{data.phone}</dd>
          <dt>Salary expectations</dt>
          <dd>{data.desired_salary}</dd>
          <dt>Created</dt>
          <dd>
            <DateTime value={data.created_at} seconds />
          </dd>
          <dt>Updated</dt>
          <dd>
            <DateTime value={data.updated_at} seconds />
          </dd>
          <dt>First synced</dt>
          <dd>
            <DateTime value={data.first_synced_at} seconds />
          </dd>
          <dt>Last synced</dt>
          <dd>
            <DateTime value={data.last_synced_at} seconds />
          </dd>
        </dl>
        <div>
          {#if data.comments.length}
            {#each data.comments as comment}
              <div class="comment">
                <Comment {comment} />
              </div>
            {/each}
          {:else}
            <h4 class="uk-text-muted">No comments</h4>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</Layout>
