<script>
  import Navbar from './Navbar.svelte';
  import PageHeader from './PageHeader.svelte';
  import PageFooter from './PageFooter.svelte';

  export let loading = false;
</script>

<style>
  main {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: minmax(0, 1fr);
  }
</style>

<main>
  <Navbar {loading} />
  <PageHeader />
  <div>
    <slot />
  </div>
  <PageFooter />
</main>
