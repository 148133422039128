<script>
  import { makeShowFileUrl } from '../../lib/api';
  import DateTime from '../../widgets/DateTime.svelte';
  import IconLink from '../../widgets/IconLink.svelte';

  /** @type {CommentInfo} */
  export let comment;

  export let maxTextHeight = undefined;
  export let replies = true;
</script>

<style>
  .uk-comment-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .comment-text {
    white-space: pre-wrap;
    font-family: Consolas, monaco, monospace;
    font-size: 13px;
    overflow-y: auto;
  }

  .files {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
  }

  /* NOTE: Reduce margins in UI-kit's comment stuff */

  .uk-comment-list .uk-comment ~ ul {
    margin: 25px 0 0 0;
    padding-left: 25px;
    list-style: none;
  }
  @media (min-width: 960px) {
    .uk-comment-list .uk-comment ~ ul {
      padding-left: 35px;
    }
  }
  .uk-comment-list .uk-comment ~ ul > :nth-child(n + 2) {
    margin-top: 25px;
  }
</style>

<ul class="uk-comment-list">
  <li>
    <article class="uk-comment">
      <header class="uk-comment-header uk-grid-small uk-flex-middle" uk-grid>
        <div class="uk-width-auto">
          <img
            class="uk-comment-avatar"
            src={`https://api.adorable.io/avatars/50/${encodeURIComponent(comment.author)}.png`}
            alt="avatar" />
        </div>
        <div class="uk-width-expand">
          <h4 class="uk-comment-title uk-margin-remove">{comment.author}</h4>
          <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
            <li>
              <DateTime value={comment.created_at} />
            </li>
          </ul>
        </div>
      </header>
      <div class="uk-comment-body">
        <div class="comment-text" style={maxTextHeight ? `max-height: ${maxTextHeight}` : ''}>
          {comment.text}
        </div>
        {#if comment.files && comment.files.length}
          <ul class="files">
            {#each comment.files as file}
              <li>
                <IconLink href={makeShowFileUrl(file.id, file.filename)} icon="cloud-download">
                  {file.filename}
                </IconLink>
              </li>
            {/each}
          </ul>
        {/if}
      </div>
    </article>
    {#if comment.replies && comment.replies.length}
      {#if replies}
        <ul>
          {#each comment.replies as reply}
            <li>
              <svelte:self comment={reply} />
            </li>
          {/each}
        </ul>
      {:else}
        <div class="uk-text-muted uk-text-italic">
          + {comment.replies.length > 1 ? `${comment.replies.length} replies` : '1 reply'}
        </div>
      {/if}
    {/if}
  </li>
</ul>
