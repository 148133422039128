<script>
  import { Navigate, routeIsActive } from 'svelte-router-spa';
  export let url;
  export let exact = false;
</script>

<li class:uk-active={routeIsActive(url, !exact)}>
  <Navigate to={url}>
    <slot />
  </Navigate>
</li>
