<script>
  import Comment from './Comment.svelte';

  /** @type {CommentInfo[]} */
  export let comments;

  // NOTE: Prevent comments from being rendered until user clicks on them
  const activatedComments = {};
  function activateComment(id) {
    activatedComments[id] = true;
  }
</script>

<style>
  .CommentsTimeline {
  }
  .comment {
    display: inline-block;
    margin-right: 1px;
  }
  .comment a:hover img {
    filter: brightness(130%);
  }
  .comment a:active img {
    border: 2px #00d0ff solid !important;
    background-color: #00d0ff !important;
  }
  .comment a:focus img {
    border: 2px #1e87f0 solid;
    background-color: #1e87f0;
  }

  img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 2px transparent solid;
    transition: border 0.2s, background-color 0.2s, filter 0.4s;
  }

  .comment:not(:last-child) .delimiter {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 3px;
    border-color: transparent transparent transparent #808080;
    display: inline-block;
    margin-left: -3px;
  }

  .comment-popup {
    border: 1px solid #e5e5e5;
  }
  .comment-popup > div {
    width: 100%;
  }
</style>

<div class="CommentsTimeline">
  {#if comments && comments.length}
    {#each comments as comment}
      <div class="comment">
        <a href="#" on:click|preventDefault={() => activateComment(comment.id)}>
          <img
            src={`https://api.adorable.io/avatars/25/${encodeURIComponent(comment.author)}.png`}
            alt="avatar" />
        </a>
        <div uk-drop="mode: click; pos: top-left">
          {#if activatedComments[comment.id]}
            <div class="uk-card uk-card-secondary uk-card-small uk-card-body">
              <Comment {comment} maxTextHeight="300px" replies={false} />
            </div>
          {/if}
        </div>
        <span class="delimiter" />
      </div>
    {/each}
  {/if}
</div>
