import { CRITERIA_LIMIT, CRITERIA_QUERY_EXCLUDE_FIELDS } from './consts';
import { WEB_SERVER_URL } from './settings';
import { queryString } from './tools';

class RequestError {
  constructor(source, statusCode = null) {
    this.message = 'Request error';
    this.error = 'RequestError';
    this.statusCode = null;

    if (typeof source === 'string') {
      source = { message: source, statusCode };
    }
    Object.assign(this, source);
  }
}

function makeUrl(endpoint, query = undefined) {
  let url = WEB_SERVER_URL + endpoint;
  if (query) {
    url += queryString(query, CRITERIA_QUERY_EXCLUDE_FIELDS);
  }
  return url;
}

async function request(method, endpoint, query = undefined, body = undefined) {
  const url = makeUrl(endpoint, query);

  let headers;
  if (body) {
    headers = {
      'Content-Type': 'application/json',
    };
    body = JSON.stringify(body);
  }

  let resp;
  try {
    resp = await fetch(url, {
      method,
      body,
      headers,
      credentials: 'include',
    });
  } catch (err) {
    if (err.message === 'Failed to fetch') {
      throw new RequestError(`Server is not reachable`);
    }
    throw err;
  }

  const data = await resp.json();

  if (resp.status >= 400) {
    // Treat HTTP errors as javascript errors
    throw new RequestError(data);
  }

  return data;
}

// *********************************************************************************************************************

/**
 * @return {Promise<Principal>}
 */
export async function login(email, password, keepMeLoggedIn) {
  /** @type {LoginPayload} */
  const payload = {
    email,
    password,
    keep_me_logged_in: !!keepMeLoggedIn,
  };
  return request('POST', '/login', null, /** LoginPayload */ payload);
}

/**
 * @return {Promise<Principal>}
 */
export async function getPrincipal() {
  return request('GET', '/me');
}

export async function requestLogout() {
  return request('PUT', '/logout');
}

// *********************************************************************************************************************

/**
 * @param {QueryCriteria} criteria
 * @return {Promise<QueryResult<PositionInfo>>}
 */
export async function fetchPositions(criteria = {}) {
  return request('GET', '/positions', {
    ...criteria,
    limit: CRITERIA_LIMIT,
  });
}

export async function fetchPosition(positionId) {
  return request('GET', '/positions/' + positionId);
}

// *********************************************************************************************************************

/**
 * @param {QueryCriteria} criteria
 * @return {Promise<QueryResult<PositionInfo>>}
 */
export async function fetchCandidates(criteria = {}) {
  return request('GET', '/candidates', {
    ...criteria,
    limit: CRITERIA_LIMIT,
  });
}

export async function fetchCandidate(candidateId) {
  return request('GET', '/candidates/' + candidateId);
}

// *********************************************************************************************************************

export function makeShowFileUrl(fileId, fileName = undefined) {
  return makeUrl(`/files/${fileId}/show/${encodeURIComponent(fileName) || 'file_' + fileId}`);
}

export function makeDownloadFileUrl(fileId) {
  return makeUrl(`/files/${fileId}/download`);
}
