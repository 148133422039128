<script>
  import { navigateTo } from 'svelte-router-spa';
  import { routeStore } from '../lib/routing';
  import { queryString } from '../lib/tools';
  import { CRITERIA_URL_EXCLUDE_FIELDS } from '../lib/consts';

  let currentRoute;
  routeStore.subscribe(value => {
    currentRoute = value;
  });

  let nextFilter = null;
  let debounceTimeout = null;

  let currentFilter;
  $: {
    currentFilter =
      (currentRoute && currentRoute.queryParams && currentRoute.queryParams.filter) || '';
  }

  function handleInput(event) {
    nextFilter = event.target.value;

    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(onDebounced, 300);
  }

  function onDebounced() {
    debounceTimeout = null;
    if (currentFilter !== nextFilter) {
      setFilter(nextFilter);
    }
  }

  function clear() {
    clearTimeout(debounceTimeout);
    setFilter('');
  }

  function setFilter(filter) {
    nextFilter = null;
    navigateTo(
      currentRoute.path +
        queryString(
          {
            ...currentRoute.queryParams,
            filter,
          },
          CRITERIA_URL_EXCLUDE_FIELDS
        )
    );
  }
</script>

<style>
  .debouncing-indicator {
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: linear;
  }
</style>

<div class="uk-inline uk-display-block">
  <span class="uk-form-icon" uk-icon="icon: search" />
  <input
    class="uk-input"
    on:input={handleInput}
    value={nextFilter !== null ? nextFilter : currentFilter} />
  {#if debounceTimeout}
    <span
      class="debouncing-indicator uk-form-icon uk-form-icon-flip uk-animation-shake"
      uk-icon="icon: pencil" />
  {:else if currentFilter}
    <button class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" on:click={clear} />
  {/if}
</div>
