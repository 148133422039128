<script>
  import { makeShowFileUrl } from '../../lib/api';
  import IconLink from '../../widgets/IconLink.svelte';

  export let fallback = '<span class="uk-text-muted uk-text-italic">None</span>';

  /** @type CandidateInfo */
  export let candidate;
</script>

<span>
  {#if candidate.resume_file_id}
    <IconLink
      href={makeShowFileUrl(candidate.resume_file_id, candidate.resume_file_name)}
      icon="file-pdf"
      tooltip={candidate.resume_file_name} />
  {/if}
  {#if candidate.cover_letter_file_id}
    <IconLink
      href={makeShowFileUrl(candidate.cover_letter_file_id, candidate.cover_letter_file_name)}
      icon="file-text"
      tooltip={candidate.cover_letter_file_name} />
  {/if}
  {#if !candidate.resume_file_id && !candidate.cover_letter_file_id}
    {@html fallback}
  {/if}
</span>
