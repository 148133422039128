<script>
  import { createEventDispatcher } from 'svelte';
  import { Navigate, navigateTo } from 'svelte-router-spa';

  import { queryString, loader } from '../lib/tools';
  import { routeStore, urlWrapper } from '../lib/routing';
  import { CRITERIA_URL_EXCLUDE_FIELDS, CRITERIA_LIMIT } from '../lib/consts';
  import IndicatorIcon from './IndicatorIcon.svelte';
  import DataTableCell from './DataTableCell.svelte';

  class DataTableColumn {
    constructor(/** DataTableColumn */ source) {
      /** Property to render. Also, what will be sent as sort-by column. Can be comma separated */
      this.field = undefined;

      /** Column title */
      this.title = undefined;

      /**
       * Optional render function which can return two values.
       *   - string or number, which will be rendered as HTML
       *   - an object with format {component, ...}, which will be splatted against <svelte:component>
       * @type {function(value: *, item: *, DataTableColumn)}
       */
      this.render = undefined;

      /**
       * A helper renderer that gives you item as the first argument
       * @type {function(item: *, DataTableColumn)}
       */
      this.renderItem = undefined;

      /**
       * If this is set to URL, makes the cell clickable and leads to this url.
       */
      this.urlTo = undefined;

      Object.assign(this, source);
    }
  }

  /** @type {DataTableColumn[]} */
  export let columns;

  /** @type {QueryResult} */
  export let data;

  export let loading = loader.initial;

  let currentRoute;
  routeStore.subscribe(value => {
    currentRoute = value;
  });

  const dispatch = createEventDispatcher();

  function handleLoadMoreClick() {
    dispatch('loadMore', data);
  }

  function criteriaUrl(/** QueryResult */ data, /** QueryCriteria */ patch = undefined) {
    if (!data) {
      return '';
    }

    const params = {
      ...data.criteria,
      ...patch,
    };
    return currentRoute.path + queryString(params, CRITERIA_URL_EXCLUDE_FIELDS);
  }

  function columnSortUrl(/** QueryResult */ data, /** DataTableColumn */ column) {
    if (!data) {
      return currentRoute.path;
    }
    if (column.field === data.criteria.sort_field) {
      // Current column, flip sort
      return criteriaUrl(data, {
        sort_direction: data.criteria.sort_direction === 'asc' ? 'desc' : 'asc',
      });
    }
    return criteriaUrl(data, {
      sort_field: column.field,
      sort_direction: 'asc',
    });
  }

  function clearFilter() {
    navigateTo(
      criteriaUrl(data, {
        filter: '',
      })
    );
  }
</script>

<style>
  .uk-table {
    margin: 0;
  }
  .column-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .column-header > :last-child {
    min-width: 21px;
  }
</style>

<div class="data-table">
  <table class="uk-table uk-table-responsive uk-table-divider uk-table-small uk-table-hover">
    <thead>
      <tr>
        {#each columns as column}
          <th>
            {#if column.field}
              <a
                href={columnSortUrl(data, column)}
                on:click={urlWrapper(columnSortUrl(data, column))}
                class="column-header">
                <span>{column.title}</span>
                {#if data && data.criteria.sort_field === column.field}
                  <span
                    uk-icon="icon: {data.criteria.sort_direction === 'asc' ? 'triangle-up' : 'triangle-down'}" />
                {:else}
                  <span />
                {/if}
              </a>
            {:else}
              <span>{column.title}</span>
            {/if}
          </th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#if data && data.items && data.items.length}
        {#each data.items as item}
          <tr>
            {#each columns as column}
              <DataTableCell {item} {column} />
            {/each}
          </tr>
        {/each}
      {:else if data && data.items && !data.items.length}
        <tr>
          <td colspan="100" class="uk-text-center">
            <div class="uk-padding-large">
              {#if data.criteria && data.criteria.filter}
                <h4>It seems nothing matches the filter "{data.criteria.filter}"</h4>
                <button class="uk-button uk-button-default" on:click={clearFilter}>
                  <span uk-icon="close" />
                  Clear filter
                </button>
              {:else}
                <h4>Oops, there doesn't seem to be any data available</h4>
              {/if}
            </div>
          </td>
        </tr>
      {:else}
        <tr>
          <td colspan="100" class="uk-text-center">
            <IndicatorIcon {loading} error={!data} ratio={5} className="uk-padding-large" />
          </td>
        </tr>
      {/if}
    </tbody>
  </table>
  {#if data && data.items && data.items.length}
    <button
      class="uk-button uk-button-default uk-width-1-1 uk-margin-medium-bottom"
      disabled={!data.has_more || loading}
      on:click={handleLoadMoreClick}>
      <IndicatorIcon {loading} icon="pull" />
      {#if data.has_more}Load {CRITERIA_LIMIT} more{:else}Nothing more to load{/if}
    </button>
  {/if}
</div>
