export function caster(Ctr) {
  return val => {
    if (!val || val instanceof Ctr) {
      return val;
    }
    return new Ctr(val);
  };
}

export function queryString(obj, excludeSet = undefined) {
  if (!obj) {
    return '';
  }

  const items = [];
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== undefined &&
      (!excludeSet || !excludeSet.has(key))
    ) {
      items.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
  }
  return '?' + items.join('&');
}

/**
 * @callback cancellatorFn
 * @template T
 * @param T
 * @returns Promise<T>
 */

/**
 * Cancellable promise handler. It compares the initial loading tokan with the current loading token,
 * and if they don't match, kills the promise.
 * @return {cancellatorFn}
 */
export function cancellator(initialToken, currentToken) {
  return result => {
    return new Promise(resolve => {
      const finalState = currentToken();
      if (initialToken === finalState) {
        resolve(result);
      }

      // Otherwise, we stay here... forever!
    });
  };
}

/**
 * Creates a function which appends newly loaded query results with previous query results.
 * This is used for pagination / "load more" functionality
 * @param {QueryResult} originalData
 */
export function queryResultAppender(originalData) {
  return (/** QueryResult */ freshData) => {
    if (!freshData) {
      return originalData;
    }
    if (originalData && freshData.criteria.skip > 0) {
      return {
        ...freshData,
        items: originalData.items.concat(freshData.items),
      };
    }

    return freshData;
  };
}

/**
 * Helper token generator for loading states. Initial and done are consts to set initially
 * and reset after loading. Call start() to get the next unique loading token.
 */
export const loader = {
  _LAST_TOKEN: 0,
  start() {
    return ++this._LAST_TOKEN;
  },
  get initial() {
    return undefined;
  },
  get done() {
    return null;
  },
};

/**
 * Pretty-print date
 * @param {Date} value
 * @param seconds
 */
export function dateToString(value, seconds = false) {
  let dateString =
    value.getFullYear() +
    '/' +
    String(value.getMonth() + 1).padStart(2, '0') +
    '/' +
    String(value.getDate()).padStart(2, '0') +
    ' ' +
    String(value.getHours()).padStart(2, '0') +
    ':' +
    String(value.getMinutes()).padStart(2, '0');

  if (seconds) {
    dateString += ':' + String(value.getSeconds()).padStart(2, '0');
  }
  return dateString;
}

/**
 * Show a toast using ui-kit
 * @param html
 * @param {'primary'|'success'|'warning'|'danger'|} status
 * @param {UIIcon} icon
 */
export function toast(html, status = undefined, icon = undefined) {
  if (icon) {
    html = `<span uk-icon='icon: ${icon}'></span> ${html}`;
  }
  window.UIkit.notification(html, { pos: 'bottom-right', status, timeout: 2000 });
}
