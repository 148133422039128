<script>
  import { Route } from 'svelte-router-spa';
  import { routeStore } from '../lib/routing';

  export let currentRoute;
  export let params;

  $: routeStore.update(() => currentRoute);
</script>

<Route {currentRoute} {params} />
