<script>
  import { onMount } from 'svelte';

  import Layout from '../../layout/Layout.svelte';
  import PageTitle from '../../layout/PageTitle.svelte';
  import DataTable from '../../widgets/DataTable.svelte';
  import CriteriaFilter from '../../widgets/CriteriaFilter.svelte';
  import { fetchPositions } from '../../lib/api';
  import { handleError } from '../../lib/errors';
  import { ROUTES, breadcrumbsStore } from '../../lib/routing';
  import { cancellator, caster, loader, queryResultAppender } from '../../lib/tools';
  import { CRITERIA_LIMIT } from '../../lib/consts';

  export let currentRoute;
  export let params = {};

  onMount(() => breadcrumbsStore.update(() => [ROUTES.positions()]));

  // Code

  /** @type {QueryResult<PositionInfo>} */
  let data = null;
  let loading = loader.initial;

  async function loadData(criteria = {}) {
    loading = loader.start();
    data = await fetchPositions({
      ...criteria,
    })
      .catch(handleError)
      .then(cancellator(loading, () => loading))
      .then(queryResultAppender(data));
    loading = loader.done;
  }

  $: loadData(currentRoute.queryParams);

  /** @type {Array<DataTableColumn>} */
  const columns = [
    { field: 'id', title: 'ID' },
    { field: 'title', title: 'Title', urlTo: pi => ROUTES.positionDetails(pi.id).url },
    { field: 'status', title: 'Status' },
    { field: 'location', title: 'Location' },
    { field: 'minimum_experience', title: 'Exp' },
    { field: 'created_at', title: 'Created', render: caster(Date) },
    { field: 'last_posted_at', title: 'Last posted', render: caster(Date) },
    { field: 'filled_at', title: 'Filled', render: caster(Date) },
  ];

  function handleLoadMore() {
    if (data && data.has_more) {
      loadData({
        ...data.criteria,
        skip: data.criteria.skip + CRITERIA_LIMIT,
      });
    }
  }
</script>

<Layout {loading}>
  <PageTitle>
    Positions
    <div slot="right">
      <CriteriaFilter />
    </div>
  </PageTitle>
  <div class="uk-container uk-container-expand uk-margin-top">
    <DataTable {data} {columns} {loading} on:loadMore={handleLoadMore} />
  </div>
</Layout>
