<script>
  import { onMount } from 'svelte';
  import { Navigate } from 'svelte-router-spa';

  import { fetchCandidates } from '../../lib/api';
  import { handleError } from '../../lib/errors';
  import { ROUTES, breadcrumbsStore } from '../../lib/routing';
  import { cancellator, caster, loader, queryResultAppender } from '../../lib/tools';
  import { CRITERIA_LIMIT } from '../../lib/consts';

  import Layout from '../../layout/Layout.svelte';
  import PageTitle from '../../layout/PageTitle.svelte';
  import DataTable from '../../widgets/DataTable.svelte';
  import CriteriaFilter from '../../widgets/CriteriaFilter.svelte';
  import StarRating from '../../widgets/StarRating.svelte';
  import CandidateLinks from './CandidateLinks.svelte';
  import CandidateFiles from './CandidateFiles.svelte';
  import CommentsTimeline from './CommentsTimeline.svelte';

  export let currentRoute;
  export let params;

  onMount(() => breadcrumbsStore.update(() => [ROUTES.candidates()]));

  /** @type {QueryResult<CandidateInfo>} */
  let data = null;
  let loading = loader.initial;

  async function loadData(criteria = {}) {
    loading = loader.start();
    data = await fetchCandidates({
      ...criteria,
    })
      .catch(handleError)
      .then(cancellator(loading, () => loading))
      .then(queryResultAppender(data));
    loading = loader.done;
  }

  $: loadData(currentRoute.queryParams);

  /** @type {Array<DataTableColumn>} */
  const columns = [
    { field: 'id', title: 'ID' },
    {
      field: 'first_name,last_name',
      title: 'Name',
      renderItem: candidate => `${candidate.first_name} ${candidate.last_name}`,
      urlTo: candidate => ROUTES.candidateDetails(candidate.id).url,
    },
    {
      field: 'email',
      title: 'Email',
      render: email => `<a href="mailto:${email}" target="_blank">${email}</a>`,
    },
    {
      field: 'position_title',
      title: 'Position',
      renderItem: /** CandidateInfo */ candidate => ({
        component: Navigate,
        to: ROUTES.positionDetails(candidate.position_id).url,
        slot: candidate.position_title,
      }),
    },
    {
      field: 'rating',
      title: 'Rating',
      renderItem: /** CandidateInfo */ candidate => ({
        component: StarRating,
        rating: candidate.rating,
      }),
    },
    { field: 'status', title: 'Status' },
    {
      field: 'twitter_username,linkedin_url,website_url',
      title: 'Links',
      renderItem: /** CandidateInfo */ candidate => ({ component: CandidateLinks, candidate }),
    },
    {
      field: 'resume_file_id,cover_letter_file_id',
      title: 'Files',
      renderItem: /** CandidateInfo */ candidate => ({ component: CandidateFiles, candidate }),
    },
    {
      title: 'Comments',
      renderItem: /** CandidateInfo */ candidate => ({
        component: CommentsTimeline,
        comments: candidate.comments,
      }),
    },
    { field: 'created_at', title: 'Created', render: caster(Date) },
    { field: 'updated_at', title: 'Updated', render: caster(Date) },
  ];

  function handleLoadMore() {
    if (data && data.has_more) {
      loadData({
        ...data.criteria,
        skip: data.criteria.skip + CRITERIA_LIMIT,
      });
    }
  }
</script>

<Layout {loading}>
  <PageTitle>
    Candidates
    <div slot="right">
      <CriteriaFilter />
    </div>
  </PageTitle>
  <div class="uk-container uk-container-expand uk-margin-top">
    <DataTable {data} {columns} {loading} on:loadMore={handleLoadMore} />
  </div>
</Layout>
