<script>
  import { onMount } from 'svelte';
  import { Router } from 'svelte-router-spa';

  import { ROUTES, initDocumentTitleUpdater } from './lib/routing';
  import { loadPrincipal, principalStore } from './lib/auth';

  import RoutingContext from './layout/RoutingContext.svelte';
  import HomePage from './pages/HomePage.svelte';
  import LoginPage from './pages/LoginPage.svelte';
  import PositionsPage from './pages/positions/PositionsPage.svelte';
  import PositionDetailsPage from './pages/positions/PositionDetailsPage.svelte';
  import CandidatesPage from './pages/candidates/CandidatesPage.svelte';
  import CandidateDetailsPage from './pages/candidates/CandidateDetailsPage.svelte';

  /** @type {Principal} */
  let principal = undefined;
  principalStore.subscribe(value => {
    principal = value;
  });

  const routes = [
    route(ROUTES.home(), HomePage),
    route(ROUTES.login(), LoginPage, true),
    route(ROUTES.positions(), PositionsPage),
    route(ROUTES.positionDetails(':id'), PositionDetailsPage),
    route(ROUTES.candidates(), CandidatesPage),
    route(ROUTES.candidateDetails(':id'), CandidateDetailsPage),
  ];

  onMount(initDocumentTitleUpdater);
  onMount(loadPrincipal);

  function route(routeOb, component, anonymous = false) {
    return {
      name: routeOb.url,
      component,
      layout: RoutingContext,
      onlyIf: anonymous
        ? undefined
        : {
            guard: isLoggedIn,
            redirect: ROUTES.login().url,
          },
    };
  }

  function isLoggedIn() {
    return !!principal;
  }
</script>

{#if principal !== undefined}
  <Router {routes} />
{/if}
