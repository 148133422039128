<script>
  import { ROUTES } from '../lib/routing';
  import { principalStore, logout } from '../lib/auth';
  import NavbarItem from './NavbarItem.svelte';
  import IndicatorIcon from '../widgets/IndicatorIcon.svelte';

  export let loading = false;

  /** @type {Principal} */
  let principal = undefined;
  principalStore.subscribe(value => {
    principal = value;
  });

  const navbarItems = principal
    ? [
        { route: ROUTES.home(), exact: true },
        { route: ROUTES.positions() },
        { route: ROUTES.candidates() },
      ]
    : [{ route: ROUTES.login(), exact: true }];
</script>

<style>
  .logo {
    height: 2rem;
  }
  .principal img {
    max-width: 2rem;
    max-height: 2rem;
    display: inline-block;
    margin-right: 0.5rem;
  }
</style>

<nav class="uk-navbar-container">
  <div class="uk-container">
    <div class="uk-navbar" uk-navbar="mode: click">
      <div class="uk-navbar-left">
        <a class="uk-navbar-item uk-logo" href="#">
          <img src="/images/logo.png" alt="Logo" class="logo" />
        </a>
        <ul class="uk-navbar-nav uk-visible@s">
          {#each navbarItems as item}
            <NavbarItem url={item.route.url} exact={item.exact}>{item.route.title}</NavbarItem>
          {/each}
        </ul>
      </div>
      <div class="uk-navbar-right">
        <IndicatorIcon {loading} />
        {#if principal}
          <ul class="uk-navbar-nav uk-visible@s">
            <li>
              <a href="#" class="principal">
                <img src={principal.avatar_url} alt="avatar" class="" />
                {principal.display_name}
              </a>
              <div class="uk-navbar-dropdown" uk-dropdown="pos: bottom-right">
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li>
                    <a href="#" on:click|preventDefault|stopPropagation={logout}>
                      <span uk-icon="lock" />
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        {/if}
        <a
          class="uk-navbar-toggle uk-hidden@s"
          uk-navbar-toggle-icon
          uk-toggle="target: #Navbar-mobile"
          href="#" />
      </div>
    </div>
  </div>
</nav>

<div id="Navbar-mobile" uk-offcanvas="mode: slide; flip: true">
  <div class="uk-offcanvas-bar uk-flex uk-flex-column">
    <button class="uk-offcanvas-close" type="button" uk-close />
    <ul class="uk-nav-center uk-margin-auto-vertical uk-nav-primary" uk-nav>
      {#each navbarItems as item}
        <NavbarItem url={item.route.url} exact={item.exact}>{item.route.title}</NavbarItem>
      {/each}
      {#if principal}
        <li>
          <a href="#" on:click|preventDefault|stopPropagation={logout}>Logout</a>
        </li>
      {/if}
    </ul>
  </div>
</div>
