<script>
  import IconLink from '../../widgets/IconLink.svelte';

  export let fallback = '';

  /** @type CandidateInfo */
  export let candidate;

  let websiteIcon;
  $: websiteIcon = /github\.com/i.test(candidate.website_url || '') ? 'github' : 'link';
</script>

<span>
  {#if candidate.twitter_username}
    <IconLink
      href={`https://twitter.com/${candidate.twitter_username}`}
      icon="twitter"
      tooltip={'@' + candidate.twitter_username} />
  {/if}
  <IconLink href={candidate.linkedin_url} icon="linkedin" tooltip={candidate.linkedin_url} />
  <IconLink href={candidate.website_url} icon={websiteIcon} tooltip={candidate.website_url} />
  {#if !candidate.twitter_username && !candidate.linkedin_url && !candidate.website_url}
    {@html fallback}
  {/if}
</span>
