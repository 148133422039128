import { logout } from './auth';
import { toast } from './tools';

/**
 * @param {Error|CustomError|RequestError|Boom} err
 */
export function renderErrorMessage(err) {
  const label = err.statusCode || err.code || err.status || 'ERROR';
  const message = err.message || err.error || String(err);
  const html = `<span class="uk-label uk-label-danger">${label}</span> ${message}`;
  return html;
}

export function errorToast(err) {
  toast(renderErrorMessage(err), 'danger');
}

export function handleError(err) {
  console.error(err);

  errorToast(err);

  if (err.statusCode === 401) {
    logout(); // Intentional async
  }

  return undefined;
}
