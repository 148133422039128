<script>
  import { loader } from '../lib/tools';

  export let loading = false;
  export let error = false;
  export let icon = null;
  export let ratio = 1;
  export let className = '';
</script>

{#if loading}
  <div uk-spinner="ratio: {ratio / 1.5}" class={className} />
{:else if loading !== loader.initial}
  {#if error}
    <span uk-icon="icon: ban; ratio: {ratio}" class={className} />
  {:else if icon}
    <span uk-icon="icon: {icon}; ratio: {ratio}" class={className} />
  {/if}
{/if}
