<script>
  import { onMount } from 'svelte';
  import { Navigate } from 'svelte-router-spa';

  import Layout from '../layout/Layout.svelte';
  import PageTitle from '../layout/PageTitle.svelte';
  import { ROUTES, breadcrumbsStore } from '../lib/routing';

  export let currentRoute;
  export let params;

  onMount(() => breadcrumbsStore.update(() => []));

  const sections = [
    {
      title: 'Positions',
      url: ROUTES.positions().url,
      text: 'Job openings, past and active',
      icon: 'users',
    },
    {
      title: 'Candidates',
      url: ROUTES.candidates().url,
      text: 'Job candidates applications, uploaded files and reviews',
      icon: 'receiver',
    },
  ];
</script>

<Layout>
  <PageTitle>Home</PageTitle>
  <div class="uk-section uk-section-muted uk-margin-medium-top">
    <div class="uk-container">
      <div class="uk-grid-match uk-child-width-1-2@m" uk-grid>
        {#each sections as section}
          <div class="uk-text-center">
            <span uk-icon="icon: {section.icon}; ratio: 5" />
            <h4>
              <Navigate to={section.url}>{section.title}</Navigate>
            </h4>
            <p>{section.text}</p>
          </div>
        {/each}
      </div>
    </div>
  </div>
</Layout>
