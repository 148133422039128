<script>
  import { Navigate } from 'svelte-router-spa';

  import { ROUTES, breadcrumbsStore } from '../lib/routing';

  /** @type {RouteInfo[]} */
  let breadcrumbs = [];
  breadcrumbsStore.subscribe(value => (breadcrumbs = [ROUTES.home(), ...value]));
</script>

<style>
  .page-header {
    background: hsla(0, 0%, 92%, 1);
    padding: 0.5rem 0;
  }
  .uk-breadcrumb {
    margin: 0;
  }

  .back-link {
    margin-top: 1rem;
  }
  :global(.back-link a) {
    font-weight: bold;
  }

  @media (min-width: 640px) {
    .back-link {
      margin-top: 0;
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
</style>

<div class="page-header">
  <div class="uk-container">
    <div class="content">
      <ul class="uk-breadcrumb">
        {#each breadcrumbs as breadcrumb, index}
          <li>
            {#if index === breadcrumbs.length - 1}
              <span>{breadcrumb.title}</span>
            {:else}
              <Navigate to={breadcrumb.url}>{breadcrumb.title}</Navigate>
            {/if}
          </li>
        {/each}
      </ul>
      {#if breadcrumbs.length >= 3}
        <div class="back-link">
          <Navigate to={breadcrumbs[breadcrumbs.length - 2].url}>
            <span uk-icon="chevron-double-left" />
            Close
          </Navigate>
        </div>
      {/if}
    </div>
  </div>
</div>
