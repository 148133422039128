import { writable } from 'svelte/store';
import { navigateTo } from 'svelte-router-spa';

export const routeStore = writable({});
export const breadcrumbsStore = writable([]);

export class RouteInfo {
  constructor(title, url) {
    this.title = title;
    this.url = url;
  }
}

export const ROUTES = {
  login: () => new RouteInfo('Login', '/login'),
  home: () => new RouteInfo('Home', '/'),
  positions: () => new RouteInfo('Positions', '/positions'),
  positionDetails: id => new RouteInfo(`Position ${id}`, `/positions/${id}`),
  candidates: () => new RouteInfo('Candidates', '/candidates'),
  candidateDetails: id => new RouteInfo(`Candidate ${id}`, `/candidates/${id}`),
};

export const DEFAULT_LOGGED_IN_ROUTE = ROUTES.home();
export const DEFAULT_LOGGED_OUT_ROUTE = ROUTES.login();

export function urlWrapper(url) {
  return event => {
    event.preventDefault();
    event.stopPropagation();
    navigateTo(url);
  };
}

export function initDocumentTitleUpdater(suffix = ' - Bamboo Toolbox') {
  breadcrumbsStore.subscribe((/** RouteInfo[] */ breadcrumbs) => {
    const currentRoute = breadcrumbs[breadcrumbs.length - 1] || ROUTES.home();
    document.title = currentRoute.title + suffix;
  });
}
