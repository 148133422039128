<script>
  import { onMount } from 'svelte';
  import { Navigate } from 'svelte-router-spa';

  import Layout from '../../layout/Layout.svelte';
  import PageTitle from '../../layout/PageTitle.svelte';
  import BooleanBadge from '../../widgets/BooleanBadge.svelte';
  import DateTime from '../../widgets/DateTime.svelte';
  import { ROUTES, breadcrumbsStore } from '../../lib/routing';
  import { fetchPosition } from '../../lib/api';
  import { handleError } from '../../lib/errors';
  import { cancellator, loader } from '../../lib/tools';
  import IndicatorIcon from '../../widgets/IndicatorIcon.svelte';

  export let currentRoute;
  export let params;

  onMount(() =>
    breadcrumbsStore.update(() => [
      ROUTES.positions(),
      ROUTES.positionDetails(currentRoute.namedParams.id),
    ])
  );

  /** @type {PositionInfo} */
  let data = null;
  let loading = loader.initial;

  async function loadData(id) {
    loading = loader.start();
    data = await fetchPosition(id)
      .catch(handleError)
      .then(cancellator(loading, () => loading));
    loading = loader.done;
  }

  onMount(() => loadData(currentRoute.namedParams.id));
</script>

<Layout {loading}>
  <div class="uk-container">
    {#if !data}
      <IndicatorIcon {loading} error ratio={5} className="uk-position-center" />
    {:else}
      <PageTitle>{data.title}</PageTitle>

      <dl class="uk-description-list uk-description-list-divider">
        <dt>Opening name</dt>
        <dd>{data.job_opening_name}</dd>
        <dt>Status</dt>
        <dd>{data.status}</dd>
        <dt>Minimum experience</dt>
        <dd>{data.minimum_experience}</dd>
        <dt>Location</dt>
        <dd>{data.location}</dd>
        <dt>Syndication</dt>
        <dd>
          <BooleanBadge on={data.syndicate}>Syndicated</BooleanBadge>
          <BooleanBadge on={data.post_to_glassdoor}>Posted to glassdoor</BooleanBadge>
          <BooleanBadge on={data.post_to_indeed}>Posted to indeed</BooleanBadge>
        </dd>
        <dt>Created</dt>
        <dd>
          <DateTime value={data.created_at} seconds />
        </dd>
        <dt>First posted</dt>
        <dd>
          <DateTime value={data.posted_at} seconds />
        </dd>
        <dt>Last posted</dt>
        <dd>
          <DateTime value={data.last_posted_at} seconds />
        </dd>
        <dt>Filled</dt>
        <dd>
          <DateTime value={data.filled_at} seconds fallback="(Not filled)" />
        </dd>
        <dt>Description</dt>
        <dd>
          {@html data.description}
        </dd>
        <dt>First synced</dt>
        <dd>
          <DateTime value={data.first_synced_at} seconds />
        </dd>
        <dt>Last synced</dt>
        <dd>
          <DateTime value={data.last_synced_at} seconds />
        </dd>
      </dl>
    {/if}
  </div>
</Layout>
