<script>
  import { onMount } from 'svelte';
  import { Navigate, navigateTo } from 'svelte-router-spa';

  import { renderErrorMessage } from '../lib/errors';
  import { ROUTES, breadcrumbsStore, DEFAULT_LOGGED_IN_ROUTE } from '../lib/routing';
  import { loader, cancellator } from '../lib/tools';
  import { login } from '../lib/api';
  import { principalStore } from '../lib/auth';
  import Layout from '../layout/Layout.svelte';
  import IndicatorIcon from '../widgets/IndicatorIcon.svelte';

  export let currentRoute;
  export let params;

  onMount(() => breadcrumbsStore.update(() => [ROUTES.login()]));

  let error = null;
  let email = '';
  let password = '';
  let keepMeLoggedIn = true;
  let loading = loader.initial;

  async function handleSubmit() {
    loading = loader.start();
    const principal = await login(email, password, keepMeLoggedIn)
      .then(cancellator(loading, () => loading))
      .catch(err => {
        error = err;
        return null;
      });
    loading = loader.done;

    principalStore.update(() => principal);
    if (principal) {
      // Logged in, redirect user to the homepage
      navigateTo(DEFAULT_LOGGED_IN_ROUTE.url);
    }
  }
</script>

<Layout {loading}>
  <div class="uk-container">
    <div
      class="uk-padding-large uk-padding-remove-horizontal uk-width-expand uk-width-large@s
      uk-align-center">
      <form on:submit|preventDefault={handleSubmit}>
        <fieldset class="uk-fieldset">
          <legend class="uk-legend">Login</legend>

          {#if error}
            <div uk-alert class="uk-alert-danger">
              {#if !loading}
                <a class="uk-alert-close" uk-close on:click={() => (error = null)} />
              {/if}
              <div>
                {@html renderErrorMessage(error)}
              </div>
            </div>
          {/if}

          <div class="uk-margin">
            <label class="uk-form-label" for="LoginEmail">Login</label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                id="LoginEmail"
                type="text"
                placeholder="name@example.com"
                bind:value={email} />
            </div>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label" for="LoginPassword">Password</label>
            <div class="uk-form-controls">
              <input class="uk-input" id="LoginPassword" type="password" bind:value={password} />
            </div>
          </div>

          <div class="uk-margin">
            <label>
              <input class="uk-checkbox" type="checkbox" bind:checked={keepMeLoggedIn} />
              Keep me logged in
            </label>
          </div>
        </fieldset>

        <button class="uk-button uk-button-primary" type="submit" disabled={loading}>
          <IndicatorIcon {loading} icon="unlock" />
          Login
        </button>
      </form>
    </div>
  </div>
</Layout>
