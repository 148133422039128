<script>
  export let on;
</script>

<style>
  .uk-label {
    cursor: default;
  }
</style>

<span class="uk-label" class:uk-label-success={on}>
  <span uk-icon="icon: {on ? 'check' : 'close'}; ratio: 0.8" />
  <slot />
</span>
